import React, { useState, useEffect } from "react"
import { ProvisionalContent, ProvisionalPost, ChannelAccount, ChannelConstraints, ProvisionalBody, ChannelIdentifier, ProvisionalContentMedium, Channel, PostOnChannel } from "../types";


type Poc = ProvisionalPost["post_on_channels_attributes"][0]

type PostOnChannelAttributes = ProvisionalPost["post_on_channels_attributes"][0]
type ErrorIdentifier = "character_limit" | "picture_limit" | "video_count" | "embedded_link_count" | "enforce_video" | "manual_parameters" | "tiktok_settings_validation" | "date_missing"

// format final : 
export type PubError = {
  affectedTargets: ProvisionalPost["post_on_channels_attributes"],
  actions: { label: string, callback: (arg: any) => void }[],
  message: string,
  inactionStrategyMessage: string,
  errorIdentifier: ErrorIdentifier,
  shouldBe: number | boolean
}

type ConcatenedKey = `${ChannelIdentifier | "global"}|${ErrorIdentifier}`

export type ValidationObject = {
  errors: { [K in ConcatenedKey]?: PubError }
  reverseMap: Record<number, ConcatenedKey[]>
  hasNewErrors: boolean
}


const usePubValidation = ({
  pocs,
  bcgs,
  instantPublish = true,
  hChannelAccounts = {},
  hBodies,
  hContentMedia,
  setOpenModalForPoc,
  createNewBodyForGroup,
  deleteGroup,
  channelConstraints
}: {
  pocs?: Poc[],
  bcgs?: ProvisionalContent["body_channel_groups_attributes"],
  channelConstraints?: Record<ChannelIdentifier, ChannelConstraints>,
  instantPublish?: boolean,
  hChannelAccounts?: Record<number, ChannelAccount>,
  hBodies: Record<number, ProvisionalBody>,
  hContentMedia: Record<number, ProvisionalContentMedium>,
  setOpenModalForPoc?: (poc: Poc) => void,
  createNewBodyForGroup?: (targets: any[], err: string) => void,
  deleteGroup?: (targets: any[]) => void,
}): ValidationObject => {

  const [errors, setErrors] = useState<ValidationObject["errors"]>({})
  const [reverseMap, setReverseMap] = useState<ValidationObject["reverseMap"]>({})
  const [hasNewErrors, setHasNewErrors] = useState(false)

  const addError = (
    errorList: ValidationObject["errors"],
    reverseList: ValidationObject["reverseMap"],
    chan: ChannelIdentifier | "global",
    errIdentifier: ErrorIdentifier,
    error: PubError,
    poc: PostOnChannelAttributes | null
  ) => {
    const fullIdentifier = [chan, errIdentifier].join("|") as ConcatenedKey
    const currentError = errorList[fullIdentifier] || error
    const affectedTargets = currentError.affectedTargets
    if (poc) {
      affectedTargets.push(poc)
      const currentKeys = reverseList[poc.channel_account_id] || []
      if (!currentKeys.includes(fullIdentifier)) {
        reverseList[poc.channel_account_id] = currentKeys.concat(fullIdentifier)
      }
    }
    errorList[fullIdentifier] = { ...currentError, affectedTargets }
  }


  const validationRules: ValidationRules = {
    character_limit: {
      evaluation: (s, p, b, m) => Boolean(!b.message || s - b.message.length >= 0), rawMsg: "$CHAN limite le texte à $SHOULDBE caractères", inactionStrategyMsg: "le message va être tronqué",
      actions: [
        {
          label: "corriger",
          callback: (pocs) => createNewBodyForGroup?.(pocs, "character_limit")
        }
      ]
    },
    enforce_video: {
      evaluation: (s, p, b, m) => (
        !s || (m.filter(me => me?.serialized_file.resource_type === "video").length > 0)
      ),
      rawMsg: "$CHAN nécessite une vidéo",
      inactionStrategyMsg: "le post ne sera pas publié",
      actions: [
        {
          label: "supprimer",
          callback: (pocs) => deleteGroup?.(pocs)
        }
      ]
    },
    picture_limit: {
      evaluation: (s, p, b, m) => (s - b.body_media_attributes.length >= 0),
      rawMsg: "$CHAN limite à $SHOULDBE images",
      inactionStrategyMsg: "seules les $SHOULDBE premières images seront visibles",
      actions: [
        {
          label: "corriger",
          callback: (pocs) => createNewBodyForGroup?.(pocs, "picture_limit")
        }
      ]
    },
  }


  const buildConstraintsErrors = (
    { reverseList, errorList, constraints, validationRules, poc, body, media, channelIdentifier, channelName,
    }: {
      errorList: ValidationObject["errors"],
      reverseList: ValidationObject["reverseMap"],
      constraints: ChannelConstraints,
      validationRules: ValidationRules,
      poc: Poc | null,
      body: ProvisionalBody,
      media: ProvisionalContentMedium[],
      channelIdentifier: ChannelIdentifier,
      channelName?: string
    }
  ) => {
    Object.entries(constraints).forEach((
      [constraint, shouldBe]: any
    ) => {

      const validationRule = validationRules[constraint as keyof ChannelConstraints]

      if (validationRule) {
        const { evaluation, rawMsg, inactionStrategyMsg, actions } = validationRule

        if (!evaluation(shouldBe, poc, body, media)) {

          const message = rawMsg
            .replaceAll("$CHAN", channelName || "")
            .replaceAll("$SHOULDBE", shouldBe || "")

          const inactionStrategyMessage = `Sans action de votre part, ${inactionStrategyMsg
            .replaceAll("$SHOULDBE", shouldBe || "")}`

          const error = { inactionStrategyMessage, message, actions, affectedTargets: [], shouldBe, errorIdentifier: constraint }

          addError(errorList, reverseList, channelIdentifier, constraint, error, poc)
        }
      }
    })

  }

  useEffect(() => {
    const errorList = {}
    const reverseList = {}
    if (pocs) {
      pocs?.map((poc) => {
        const channelAccount = hChannelAccounts[poc.channel_account_id];
        const body = hBodies[poc.body_provisional_id] as ProvisionalBody;
        const constraints = channelAccount?.channel_constraints;
        const bodyMediaPid = body.body_media_attributes.map(b => b.content_medium_provisional_id)
        const channelIdentifier = channelAccount?.channel?.identifier

        const media = Object.values(hContentMedia)
          .filter(v => bodyMediaPid.includes(v.provisional_id))


        if (!constraints || !body || !channelIdentifier) {
          return [];
        }

        buildConstraintsErrors({
          constraints,
          validationRules,
          poc,
          body,
          media,
          channelIdentifier,
          channelName: channelAccount.channel.name,
          errorList,
          reverseList
        })


        if (channelIdentifier === "tiktok" && !poc?.channel_specific_data?.privacy_level) {
          addError(
            errorList,
            reverseList,
            channelIdentifier,
            "tiktok_settings_validation",
            {
              inactionStrategyMessage: "Sans action de votre part, le post ne sera pas publié",
              message: "Tiktok nécessite une validation manuelle des paramètres",
              actions: [{
                callback: (pocs) => {
                  if (pocs[0]) { setOpenModalForPoc?.(pocs[0]) }
                }, label: "voir"
              }],
              affectedTargets: [],
              shouldBe: true,
              errorIdentifier: "tiktok_settings_validation"
            },
            poc
          )
        }

        if (!poc.forced_scheduled_at && !instantPublish) {
          addError(
            errorList,
            reverseList,
            "global",
            "date_missing",
            {
              inactionStrategyMessage: "Sans action de votre part, ils ne seront pas publiés",
              message: "Certains posts n'ont pas de date",
              actions: [{ callback: () => setOpenModalForPoc?.(poc), label: "voir" }],
              affectedTargets: [],
              shouldBe: true,
              errorIdentifier: "date_missing"
            },
            poc
          )
        }
      })
    } else if (bcgs) {
      bcgs?.map((bcg) => {
        const body = hBodies[bcg.body_provisional_id] as ProvisionalBody;
        bcg.channels.map(channel => {
          const constraints = channelConstraints?.[channel]
          const bodyMediaPid = body.body_media_attributes.map(b => b.content_medium_provisional_id)
          const channelIdentifier = channel

          const media = Object.values(hContentMedia)
            .filter(v => bodyMediaPid.includes(v.provisional_id))


          if (!constraints || !body || !channelIdentifier) { return }

          buildConstraintsErrors({
            constraints,
            validationRules,
            poc: null,
            body,
            media,
            channelIdentifier,
            channelName: channel,
            errorList,
            reverseList
          })
        })
      })

    }
    const newErrorCount = Object.keys(errorList).length
    if (newErrorCount > Object.keys(errors).length) {
      setHasNewErrors(true)
    }
    setErrors(errorList)
    setReverseMap(reverseList)
  }, [
    pocs,
    bcgs,
    instantPublish,
    // hChannelAccounts,
    hBodies,
    hContentMedia,
  ])
  console.log(errors)
  console.log(reverseMap)

  useEffect(() => {
    if (hasNewErrors) {
      setTimeout(() => setHasNewErrors(false), 1000); // Reset animation after 1 second
    }
  }, [hasNewErrors]);

  return { errors, reverseMap, hasNewErrors }
}




type EvaluationFn<C extends keyof ChannelConstraints> = (
  shouldBe: ChannelConstraints[C],
  poc: ProvisionalPost["post_on_channels_attributes"] | null,
  body: ProvisionalBody,
  media: ProvisionalContentMedium[]
) => boolean


type ValidationRules = {
  [K in keyof ChannelConstraints]: {
    evaluation: EvaluationFn<K>,
    rawMsg: string,
    inactionStrategyMsg: string,
    actions: { label: string, callback: (arg: any) => void }[],
  }
}

export default usePubValidation;
