import React, { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import Modal from "./Modal";
import DatePicker from "./DatePicker";
import {
  ExclamationCircleIcon,
  TrashIcon,
  XMarkIcon,
  EllipsisVerticalIcon,
  PlusIcon
} from "@heroicons/react/24/solid";
import {
  ProvisionalPost,
  ChannelAccount,
  ProvisionalContentMedium,
  ProvisionalBody,
  Channel,
  PostOnChannel,
} from "../types";
import { ValidationObject } from "../utils/channelValidators";
import { Description, Radio, RadioGroup, Switch } from "@headlessui/react";
import Select from "./Select";
import ChannelAccountImage from "./ChannelAccountImage";
import Tooltip from "./Tooltip";
import { set } from "react-hook-form";
import PostPreview from "./PostPreview";

export default function PublicationManager({
  post,
  setPost,
  validationObject,
  hChannelAccounts,
  hBodies,
  hContentMedia,
  variables,
  openModalForPoc,
  setOpenModalForPoc
}: {
  post: ProvisionalPost;
  validationObject: ValidationObject;
  setPost: React.Dispatch<React.SetStateAction<ProvisionalPost>>;
  hChannelAccounts: Record<number, ChannelAccount>;
  hBodies: Record<number, ProvisionalBody>;
  hContentMedia: Record<number, ProvisionalContentMedium>;
  variables: Record<string, string | null>;
  openModalForPoc: ProvisionalPost["post_on_channel_attributes"][0] | null,
  setOpenModalForPoc: React.Dispatch<React.SetStateAction<ProvisionalPost["post_on_channel_attributes"][0] | null>>
}) {

  const { errors, reverseMap } = validationObject


  const updatePostDatetime = (stringifiedDate: string) => {
    setPost((prev) => ({
      ...prev,
      scheduled_at: stringifiedDate,
      post_on_channels_attributes: prev.post_on_channels_attributes.map(
        (poc) => {
          return { ...poc, forced_scheduled_at: stringifiedDate };
        }
      ),
    }));
  };


  const oneHourFromNow = new Date
  oneHourFromNow.setMinutes(oneHourFromNow.getMinutes() + 15);

  const togglePublish = (instantPublish: boolean) => {
    setPost((prev) => {
      return {
        ...prev,
        scheduled_at: !instantPublish ? prev.scheduled_at : oneHourFromNow.toISOString(),
        instant_publish: instantPublish,
        post_on_channels_attributes: prev.post_on_channels_attributes.map(
          (poc) => ({
            ...poc,
            forced_scheduled_at: instantPublish ? "" : poc.forced_scheduled_at || oneHourFromNow.toISOString(),
          })
        )
      }
    })
  };

  // "one_time" | "daily" | "weekly" | "monthly"
  const periodicityOptions = [
    { label: "Une fois", value: "one_time" },
    { label: "Hebdomadaire", value: "weekly" },
    { label: "Mensuel", value: "monthly" },
  ];



  // Sort posts with errors first
  const sortedPosts = [...post?.post_on_channels_attributes].sort((a, b) => {
    const erroredPocs = Object.keys(reverseMap)
    const aHasErrors = erroredPocs.some(id => id === a?.channel_account_id.toString())
    const bHasErrors = erroredPocs.some(id => id === b?.channel_account_id.toString())
    return aHasErrors === bHasErrors ? 0 : aHasErrors ? -1 : 1;
  });

  const updatePoc = (
    poc: ProvisionalPost["post_on_channels_attributes"][0],
    attributes: Record<string, any>
  ) => {
    setPost((prev) => {

      const post_on_channels_attributes = prev.post_on_channels_attributes.map(
        (prevPoc) => {
          if (
            prevPoc.channel_account_id === poc.channel_account_id &&
            prevPoc.body_provisional_id === poc.body_provisional_id
          ) {
            return { ...prevPoc, ...attributes };
          }
          return prevPoc;
        }
      )
      return { ...prev, post_on_channels_attributes }
    })
  };

  const deletePoc = (
    poc: ProvisionalPost["post_on_channels_attributes"][0]
  ) => {
    setPost((prev) => ({
      ...prev,
      post_on_channels_attributes: prev.post_on_channels_attributes.filter(
        (prevPoc) => prevPoc.channel_account_id !== poc.channel_account_id
      ),
    }));
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row-reverse gap-5 p-5 bg-white rounded shadow justify-between">
        {/* Programmation buttons */}

        <div className="flex-1">

          <h3 className="text-lg font-medium text-gray-900 mb-4">Programmation</h3>
          <label className="rounded-tl-md rounded-tr-md relative flex cursor-pointer border border-gray-200 p-4 focus:outline-none has-[:checked]:border-brand_lighter has-[:checked]:bg-brand_lighter/30">
            <input
              type="radio"
              className="border-gray-300"
              checked={post.instant_publish}
              aria-label="publier maintenant"
              aria-description="publier Maintenant"
              onChange={(e) => {
                togglePublish(true);
              }}
            />
            <div className="flex flex-col ml-3">
              <span className="flex items-center gap-1 mb-2 font-medium text-gray-900">
                Publier maintenant
              </span>
              <span className="block text-gray-500">
                Publier le contenu immédiatemment sur les réseaux séléctionnés
              </span>
            </div>
          </label>
          <label className="rounded-bl-md rounded-br-md relative flex cursor-pointer border border-gray-200 p-4 focus:outline-none has-[:checked]:border-brand_lighter has-[:checked]:bg-brand_lighter/30">
            <input
              type="radio"
              className="border-gray-300"
              checked={!post.instant_publish}
              aria-label="publier maintenant"
              aria-description="publier Maintenant"
              onChange={(e) => togglePublish(false)}
            />
            <div className="flex flex-col w-full ml-3">
              <span className="flex items-center gap-1 mb-2 font-medium text-gray-900">
                Programmer
              </span>
              <span className="block text-gray-500">
                Choisir le moment et la récurrence de la publication
              </span>
              {!post.instant_publish && (
                <div className="flex flex-wrap justify-between w-full gap-6 mt-6">
                  <div className="flex-1">
                    <label
                      className="font-medium text-brand_darker"
                      htmlFor="datetime-post-scheduled-at"
                    >
                      Première diffusion
                    </label>

                    <Flatpickr
                      defaultValue={
                        post.scheduled_at
                          ? new Date(post.scheduled_at)?.toLocaleString("fr")
                          : oneHourFromNow.toISOString()
                      }
                      id="datetime-post-scheduled-at"
                      name="scheduled_at"
                      onChange={(dateArray: Date[]) =>
                        updatePostDatetime(dateArray[0].toISOString())
                      }
                      disabled={post.instant_publish}
                      className={`min-w-56 custom-form-input flex flex-1 rounded-md border-gray-300 shadow-sm cursor-pointer`}
                      options={{
                        enableTime: true,
                        time_24hr: true,
                        dateFormat: "d/m/Y H:i",
                        minDate: new Date(),
                      }}
                    />
                  </div>
                  <div className="flex flex-col flex-1">
                    <label
                      htmlFor="periodicity"
                      className="font-medium text-brand_darker"
                    >
                      Récurrence
                    </label>
                    <select
                      className=" min-w-56 custom-form-input"
                      name="periodicity"
                      defaultValue={post.periodicity}
                      onChange={(e) =>
                        setPost((prev) => ({
                          ...prev,
                          periodicity: e.target
                            .value as ProvisionalPost["periodicity"],
                        }))
                      }
                    >
                      {periodicityOptions.map((p) => (
                        <option key={p.value} value={p.value}>
                          {p.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </label>
        </div>
        <div className="flex-1 flex-grow">
          <div className="mb-4">
            <h3 className="text-lg font-medium text-gray-900 ">
              Personnalisation des posts
            </h3>
            <h4 className="text-gray-500">
              {post?.post_on_channels_attributes?.length} posts prêts pour la
              publication
            </h4>
          </div>

          <div className="grid items-center grid-cols-1 gap-8">
            {sortedPosts.map((poc, index) => {
              const channelAccount = hChannelAccounts[poc.channel_account_id];

              if (!channelAccount.id) return
              const pocErrors = reverseMap[channelAccount.id]?.map(identifier => errors[identifier]) || []


              return (
                <>
                  <PostPreview
                    key={poc.id}
                    errors={pocErrors}
                    body={hBodies[poc.body_provisional_id]}
                    hContentMedia={hContentMedia}
                    variables={variables}
                    header={
                      <div className="flex items-center justify-between w-full p-3 text-sm text-gray-500 rounded bg-gray-50">
                        <div className="flex items-center h-12 gap-3 px-3 min-h-12 max-h-12">
                          <ChannelAccountImage
                            identifier={
                              channelAccount?.channel
                                ?.identifier as Channel["identifier"]
                            }
                            ca={channelAccount}
                            withOutline={true}
                            isActive={true}
                            imageSize={10}
                            logoSize={4}
                            withChannelLogo={true}
                          />

                          <div className="flex flex-col">
                            {poc?.forced_scheduled_at && (
                              <p>
                                {"Publié le "}{" "}
                                {new Date(poc.forced_scheduled_at).toLocaleString("fr", { dateStyle: "short", timeStyle: "short" }
                                )}
                              </p>
                            )}
                            <p className="font-medium text-gray-700 line-clamp-2">
                              {channelAccount?.displayed_name}
                            </p>
                          </div>
                        </div>

                        <button
                          type="button"
                          onClick={() => {
                            setOpenModalForPoc(poc);
                          }}
                          className="relative border rounded hover:bg-gray-100"
                        >
                          <EllipsisVerticalIcon className="w-6 h-6" />
                        </button>
                      </div>
                    }
                  />

                </>
              );

            })}
          </div>
        </div>
      </div>

      <PocModal
        setOpenModalForPoc={setOpenModalForPoc}
        updatePoc={updatePoc}
        instantPublish={post.instant_publish}
        deletePoc={deletePoc}
        defaultDate={post?.scheduled_at || undefined}
        postOnChannel={openModalForPoc}
        validationObject={validationObject}
        hChannelAccounts={hChannelAccounts}
      />
    </>
  );
}

const PocModal = ({
  hChannelAccounts,
  validationObject,
  // errors: globalErrors,
  postOnChannel,
  updatePoc,
  defaultDate,
  instantPublish,
  deletePoc,
  setOpenModalForPoc,
}: {
  instantPublish: boolean;
  postOnChannel: ProvisionalPost["post_on_channels_attributes"][0];
  hChannelAccounts: Record<number, ChannelAccount>;
  validationObject: ValidationObject;
  defaultDate?: string;
  updatePoc: (
    poc: ProvisionalPost["post_on_channels_attributes"][0],
    attributes: Record<string, any>
  ) => void;
  deletePoc: (poc: ProvisionalPost["post_on_channels_attributes"][0]) => void;
  setOpenModalForPoc: React.Dispatch<
    React.SetStateAction<
      ProvisionalPost["post_on_channels_attributes"][0] | null
    >
  >;
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    postOnChannel && setOpen(true);
  }, [postOnChannel]);

  useEffect(() => {
    !open && setTimeout(() => setOpenModalForPoc(null), 250);
  }, [open]);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const channelAccount =
    postOnChannel && hChannelAccounts[postOnChannel.channel_account_id];
  const { errors, reverseMap } = validationObject

  const pocErrors = reverseMap[channelAccount?.id || 0]?.map(identifier => errors[identifier]) || []

  return (
    <>
      <Modal open={open} setOpen={setOpen}>
        <div className="relative flex flex-col gap-6 p-12 m-auto my-12 bg-white border rounded-lg shadow sm:flex-row">
          <XMarkIcon
            className="absolute bg-white border rounded cursor-pointer size-6 top-1 right-1 hover:bg-gray-50"
            onClick={() => setOpen(false)}
          />
          <div className="flex flex-col gap-12 lg:flex-row">
            <div className="flex flex-col justify-between gap-5 max-w-80 w-80">
              <div className="flex flex-col justify-between gap-5">
                <div className="flex items-center h-12 gap-3 min-h-12 max-h-12">
                  <div className="relative -ml-2">
                    <ChannelAccountImage
                      identifier={
                        channelAccount?.channel
                          ?.identifier as Channel["identifier"]
                      }
                      ca={channelAccount}
                      withOutline={true}
                      isActive={true}
                      imageSize={10}
                      logoSize={4}
                      withChannelLogo={true}
                    />
                  </div>
                  <p className="line-clamp-2 text-start">
                    {channelAccount?.displayed_name}
                  </p>
                </div>

                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-1 text-red-500">
                    <ul className="">
                      {pocErrors.map((error, idx) => (
                        <li key={idx} className="flex gap-3 text-start">
                          <ExclamationCircleIcon className="w-6 h-6" />
                          {`${channelAccount?.channel?.name} ${error?.message}`}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="text-start">
                  <label
                    htmlFor={`datetime-${postOnChannel?.id}`}
                    className="font-medium"
                  >
                    Personnaliser la date
                  </label>

                  <Flatpickr
                    defaultValue={
                      postOnChannel?.forced_scheduled_at || defaultDate
                    }
                    name={`datetime-${postOnChannel?.id}`}
                    onChange={(dateArray: Date[]) =>
                      updatePoc(postOnChannel, {
                        forced_scheduled_at: dateArray[0].toISOString(),
                      })
                    }
                    disabled={instantPublish}
                    className="flex flex-1 w-full border-gray-300 rounded-md shadow-sm cursor-pointer form-input focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20"
                    options={{
                      enableTime: true,
                      time_24hr: true,
                      dateFormat: "d/m/Y H:i:S",
                      minDate: new Date(),
                    }}
                  />
                </div>
              </div>
              <button
                type="button"
                className="bg-white border border-red-500 text-red-500 hover:bg-gray-50 rounded-md cursor-pointer inline-flex items-center px-2.5 py-1.5 font-medium text-sm shadow-sm duration-100 justify-center mt-12 gap-3"
                onClick={() => setIsConfirmModalOpen(true)}
              >
                <TrashIcon className="w-4 h-4" />
                Supprimer ce post
              </button>
            </div>
            {channelAccount?.channel?.identifier === "tiktok" && (
              <TiktokPubOptions
                postOnChannel={postOnChannel}
                channelAccount={channelAccount}
                updatePoc={updatePoc}
                setOpen={setOpen}
              />
            )}
            {channelAccount?.channel?.identifier === "instagram" && (
              <InstagramPubOptions
                postOnChannel={postOnChannel}
                channelAccount={channelAccount}
                updatePoc={updatePoc}
                setOpen={setOpen}
              />
            )}
          </div>
        </div>
      </Modal>

      {isConfirmModalOpen && (
        <Modal open={isConfirmModalOpen} setOpen={setIsConfirmModalOpen}>
          <div className="p-6 m-auto bg-white rounded-lg">
            <h2 className="mb-4 text-xl font-semibold">
              Confirmer la suppression
            </h2>
            <p className="mb-6">Êtes-vous sûr de vouloir supprimer ce post ?</p>
            <div className="flex justify-end gap-4">
              <button
                className="px-4 py-2 text-gray-800 duration-200 bg-gray-200 rounded-md hover:bg-gray-100"
                onClick={() => setIsConfirmModalOpen(false)}
              >
                Annuler
              </button>
              <button
                className="px-4 py-2 text-white duration-200 bg-red-500 rounded-md hover:bg-red-700"
                onClick={() => {
                  deletePoc(postOnChannel);
                  setOpen(false); // Close the first modal as well
                  setIsConfirmModalOpen(false);
                }}
              >
                Supprimer
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

const TiktokPubOptions = ({
  postOnChannel,
  channelAccount,
  updatePoc,
  setOpen,
}: {
  postOnChannel: ProvisionalPost["post_on_channels_attributes"][0];
  channelAccount: ChannelAccount;
  updatePoc: (
    poc: ProvisionalPost["post_on_channels_attributes"][0],
    attributes: Record<string, any>
  ) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [settings, setSettings] = useState<Record<string, any>>({
    ...postOnChannel?.channel_specific_data,
  });

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [errors, setErrors] = useState<Record<string, string | null>>({});

  useEffect(() => {
    const hasValue = Boolean(settings?.privacy_level);
    setErrors((prev) => ({
      ...prev,
      privacy_level: hasValue ? null : "Sélectionnez un niveau de visibilité",
    }));
  }, [settings?.privacy_level]);

  useEffect(() => {
    const error =
      isSwitchOn &&
        !settings.brand_organic_toggle &&
        !settings?.brand_content_toggle
        ? "Sélectionnez un type de contenu commercial"
        : null;
    setErrors((prev) => ({ ...prev, commercial_content: error }));
  }, [
    settings.brand_organic_toggle,
    settings.brand_content_toggle,
    isSwitchOn,
  ]);

  useEffect(() => {
    if (settings.brand_content_toggle) {
      setVisibilityOptions((prev) => [
        ...prev.filter((option) => option.value !== "SELF_ONLY"),
        { label: "Privée", value: "SELF_ONLY", disabled: true },
      ]);
    } else {
      setVisibilityOptions((prev) => [
        ...prev.filter((option) => option.value !== "SELF_ONLY"),
        { label: "Privée", value: "SELF_ONLY" },
      ]);
    }
  }, [settings.brand_content_toggle]);

  const updateSettings = (attributes: Record<string, any>) => {
    setSettings((prev) => ({
      ...prev,
      ...attributes,
    }));
  };

  const toggleSwitch = (value: boolean) => {
    setIsSwitchOn(value);
    if (!value) {
      updateSettings({
        brand_organic_toggle: false,
        brand_content_toggle: false,
      });
    }
  };

  const visibilityMapping = {
    PUBLIC_TO_EVERYONE: "Publique",
    SELF_ONLY: "Privée",
    MUTUAL_FOLLOW_FRIENDS: "Amis",
    FOLLOWER_OF_CREATOR: "Followers",
  };

  const [visibilityOptions, setVisibilityOptions] = useState<
    { label: string; value: keyof typeof visibilityMapping }[]
  >(
    channelAccount.channel_specific_data?.privacy_level_options.map(
      (option: keyof typeof visibilityMapping) => ({
        label: visibilityMapping[option],
        value: option,
      })
    )
  );

  const [isMusicModalOpen, setIsMusicModalOpen] = useState(false);

  const isErrored = Object.values(errors).some((e) => e !== null);

  return (
    <div className="flex flex-col justify-between gap-5 max-w-80 w-80">
      <div className="flex flex-col gap-3 text-start">
        <p className="font-medium">
          Paramètres spécifiques {channelAccount?.channel?.name}
        </p>
        <div className="flex gap-3">
          <Switch
            checked={!settings?.disable_comments}
            onChange={(e) => updateSettings({ disable_comments: !e })}
            className={`${!settings?.disable_comments ? "bg-green-300" : "bg-gray-200"
              }
          relative inline-flex h-6 w-11 min-w-11 items-center rounded-full focus:ring-4 focus:outline-none ring-brand_focus ring-opacity-50 focus:border focus:border-brand_focus`}
          >
            <span className="sr-only">Autoriser les commentaires</span>
            <span
              className={`${!settings?.disable_comments ? "translate-x-6" : "translate-x-1"
                }
            inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
          Autoriser les commentaires
        </div>
        <div className="flex gap-3">
          <Switch
            checked={!settings?.disable_duet}
            onChange={(e) => updateSettings({ disable_duet: !e })}
            className={`${!settings?.disable_duet ? "bg-green-300" : "bg-gray-200"
              }
          relative inline-flex h-6 w-11 min-w-11 items-center rounded-full focus:ring-4 focus:outline-none ring-brand_focus ring-opacity-50 focus:border focus:border-brand_focus`}
          >
            <span className="sr-only">Autoriser les duos</span>
            <span
              className={`${!settings?.disable_duet ? "translate-x-6" : "translate-x-1"
                }
            inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
          Autoriser les duos
        </div>
        <div className="flex gap-3">
          <Switch
            checked={!settings?.disable_stitch}
            onChange={(e) => updateSettings({ disable_stitch: !e })}
            className={`${!settings?.disable_stitch ? "bg-green-300" : "bg-gray-200"
              }
          relative inline-flex h-6 w-11 min-w-11 items-center rounded-full focus:ring-4 focus:outline-none ring-brand_focus ring-opacity-50 focus:border focus:border-brand_focus`}
          >
            <span className="sr-only">Autoriser les collages</span>
            <span
              className={`${!settings?.disable_stitch ? "translate-x-6" : "translate-x-1"
                }
            inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
          Autoriser les collages
        </div>
      </div>

      <div className="flex flex-col gap-3 mt-5 text-start">
        <div className="flex flex-col gap-1">
          <p className="font-medium">Visibilité</p>
          <Select
            value={settings?.privacy_level}
            placeHolder="Sélectionner la visibilité"
            list={visibilityOptions}
            onChange={(value) => updateSettings({ privacy_level: value })}
            error={errors.privacy_level}
          />
        </div>
      </div>

      <div className="flex flex-col gap-3 mt-5 text-start">
        <div className="flex justify-between w-full gap-3">
          <p className="mb-3 font-medium">Contenu commercial</p>
          <Switch
            checked={isSwitchOn}
            onChange={toggleSwitch}
            className={`${isSwitchOn ? "bg-green-300" : "bg-gray-200"}
            relative inline-flex h-6 w-11 min-w-11 items-center rounded-full focus:ring-4 focus:outline-none ring-brand_focus ring-opacity-50 focus:border focus:border-brand_focus`}
          >
            <span className="sr-only">Contenu sponsorisé</span>
            <span
              className={`${isSwitchOn ? "translate-x-6" : "translate-x-1"}
              inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
        </div>

        <div className="text-sm text-gray-700 text-start">
          Indique aux autres utilisateurs que cette publication fait la
          promotion d'une marque, d'un produit ou d'un service.
        </div>

        {(isSwitchOn ||
          settings?.brand_organic_toggle ||
          settings?.brand_content_toggle) && (
            <div className="flex flex-col gap-3">
              <div className="flex justify-between gap-3">
                <Tooltip
                  text="Votre photo/vidéo sera étiquetée comme 'Contenu promotionnel'"
                  position="left"
                >
                  Pour ma marque
                </Tooltip>

                <Switch
                  checked={settings?.brand_organic_toggle}
                  onChange={(e) => updateSettings({ brand_organic_toggle: e })}
                  className={`${settings?.brand_organic_toggle
                    ? "bg-green-300"
                    : "bg-gray-200"
                    }
            relative inline-flex h-6 w-11 min-w-11 items-center rounded-full focus:ring-4 focus:outline-none ring-brand_focus ring-opacity-50 focus:border focus:border-brand_focus`}
                >
                  <span className="sr-only">Pour ma marque</span>
                  <span
                    className={`${settings?.brand_organic_toggle
                      ? "translate-x-6"
                      : "translate-x-1"
                      }
              inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>
              <div
                className={`flex gap-3 justify-between ${settings?.privacy_level === "SELF_ONLY" && "text-gray-300"
                  }`}
              >
                <Tooltip
                  text="Votre photo/vidéo sera étiquetée comme 'Contenu sponsorisé'"
                  position="left"
                >
                  Rémunéré par une autre marque
                </Tooltip>

                <Switch
                  checked={settings?.brand_content_toggle}
                  onChange={(e) => updateSettings({ brand_content_toggle: e })}
                  disabled={settings?.privacy_level === "SELF_ONLY"}
                  className={`${settings?.brand_content_toggle
                    ? "bg-green-300"
                    : "bg-gray-200"
                    }
            relative inline-flex h-6 w-11 min-w-11 items-center rounded-full focus:ring-4 focus:outline-none ring-brand_focus ring-opacity-50 focus:border focus:border-brand_focus`}
                >
                  <span className="sr-only">Pour une autre marque</span>
                  <span
                    className={`${settings?.brand_content_toggle
                      ? "translate-x-6"
                      : "translate-x-1"
                      }
              inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
              </div>

              {errors.commercial_content && (
                <small className="mt-2 text-sm text-red-500">
                  {errors.commercial_content}
                </small>
              )}

              {settings?.brand_organic_toggle &&
                !settings?.brand_content_toggle && (
                  <div className="text-sm text-gray-700 text-start">
                    En postant ce contenu vous accepter les conditions
                    d'utilisation de{" "}
                    <span
                      className="italic text-indigo-500 cursor-pointer"
                      onClick={() => setIsMusicModalOpen(true)}
                    >
                      Confirmation d'utilisation de la musique
                    </span>
                  </div>
                )}
              {settings?.brand_content_toggle && (
                <div className="text-sm text-gray-700 text-start">
                  En postant ce contenu vous accepter les conditions d'utilisation
                  de{" "}
                  <a
                    href="https://www.tiktok.com/legal/page/global/bc-policy/fr"
                    target="blank"
                    className="italic text-indigo-500"
                  >
                    Politique relative au contenu de marque
                  </a>{" "}
                  et la{" "}
                  <span
                    className="italic text-indigo-500 cursor-pointer"
                    onClick={() => setIsMusicModalOpen(true)}
                  >
                    Confirmation d'utilisation de la musique
                  </span>
                </div>
              )}
              {isMusicModalOpen && (
                <Modal open={isMusicModalOpen} setOpen={setIsMusicModalOpen}>
                  <div className="max-w-md p-6 m-auto bg-white rounded-lg">
                    <h2 className="mb-4 text-xl font-semibold">
                      Confirmation d'utilisation de la musique
                    </h2>
                    <p className="mb-6">
                      Tu confirmes (a) qu'il n'y a pas de musique protégée par le
                      droit d'auteur dans la vidéo ou (b) que tu as obtenu et payé
                      toutes les licences nécessaires (y compris en ce qui
                      concerne les droits sur les compositions musicales et les
                      enregistrements maîtres dans lesquels elles sont
                      incorporées) pour utiliser la musique dans le cadre de cette
                      publication sur la plateforme TikTok. Si tu ne peux pas
                      confirmer (a) ou (b) ci-dessus, alors n'accepte pas.
                      Remarque : si tu partages quand même ta publication,
                      celle-ci pourrait être retirée.
                    </p>
                    <div className="flex justify-end gap-4">
                      <button
                        className="btn-brand-primary"
                        onClick={() => setIsMusicModalOpen(false)}
                      >
                        Fermer
                      </button>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
          )}

        <button
          type="button"
          className={`rounded-md cursor-pointer inline-flex items-center px-2.5 py-1.5 font-medium text-sm shadow-sm duration-100 justify-center mt-3
            ${!isErrored
              ? "bg-brand_main text-white hover:bg-brand_darker"
              : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
          disabled={isErrored}
          onClick={() => {
            if (!isErrored) {
              setOpen(false);
              updatePoc(postOnChannel, {
                channel_specific_data: {
                  ...postOnChannel.channel_specific_data,
                  ...settings,
                },
              });
            }
          }}
        >
          Valider les paramètres TikTok
        </button>
      </div>
    </div>
  );
};

const InstagramPubOptions = ({
  postOnChannel,
  channelAccount,
  updatePoc,
  setOpen,
}: {
  postOnChannel: ProvisionalPost["post_on_channels_attributes"][0];
  channelAccount: ChannelAccount;
  updatePoc: (
    poc: ProvisionalPost["post_on_channels_attributes"][0],
    attributes: Record<string, any>
  ) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {

  const [currentCollab, setCurrentColab] = useState<string>("")

  const [collabs, setCollabs] = useState<string[]>(postOnChannel.channel_specific_data?.collaborators) // parceque le poc ne se met pas à jour ici 


  useEffect(() => {
    // à l'unmount on update
    updatePoc(postOnChannel, { channel_specific_data: { collaborators: collabs } })
  }, [collabs])

  const submitCollab = () => {
    if (currentCollab) { setCollabs([...collabs, currentCollab]) }
    setCurrentColab("")
  }
  const removeCollab = (toRemove: string) => {
    setCollabs(collabs.filter(c => toRemove != c))
  }


  return (
    <div className="flex flex-col justify-start gap-5 max-w-80 w-80">
      <div className="flex flex-col gap-3 text-start">
        <p className="font-medium">
          Paramètres spécifiques {channelAccount?.channel?.name}
        </p>
      </div>

      <div className="flex flex-col gap-3 mt-5 text-start">
        <div className="flex flex-col gap-2">
          <p className="font-medium">Collaborateurs</p>
          <div className="gap-2 flex flex-wrap">
            {collabs.map(c => (
              <button
                type="button"
                className="group relative text-sm font-medium text-gray-500 border-gray-300 bg-gray-100 border px-1 py-0.5 rounded-md hover:border-gray-400 hover:bg-gray-200 hover:text-gray-600"
                onClick={() => removeCollab(c)}
              >
                {c}
                <div className="absolute right-0 top-0 hidden h-full group-hover:flex rounded-md overflow-hidden">
                  <div className="pl-2 pr-0.5 bg-gradient-to-r from-transparent to-40% to-gray-200 flex items-center">
                    <XMarkIcon className="size-4" />
                  </div>
                </div>
              </button>

            ))}
          </div>
          <div className="flex items-center relative">
            <input
              type="text"
              value={currentCollab}
              onChange={(e) => setCurrentColab(e.currentTarget.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  submitCollab()
                }
              }}
              className="peer pr-16 w-full text-sm form-input flex flex-1 rounded-md border-gray-300 shadow-sm focus:border-brand_focus focus:ring focus:ring-brand_focus focus:ring-opacity-20 "
              placeholder="squeezie"
            />
            <button
              type="button"
              onClick={submitCollab}
              className="peer-focus:border-brand_focus peer-focus:text-brand_main absolute flex items-center right-0 h-full w-8 p-1 font-medium bg-gray-50 rounded-r-md border border-gray-300 hover:bg-gray-100 duration-300 text-gray-500"
            >
              <PlusIcon className="inline m-auto h-4 w-4" />
            </button>
          </div>
        </div>
      </div>

    </div >
  );
};
